import NotFound from './NotFound';
import Footer from './components/common/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import OnLoadPopUp from './components/landing/OnLoadPopup.js';
import Admin from './pages/Admin.js';

const Home = lazy(() => import('./pages/Home.js'));
const AppPage = lazy(() => import("./pages/App.js"));
const Features = lazy(() => import('./pages/Features'));
const FeatureApp = lazy(() => import("./pages/FeatureApp"))
const FeatureClub = lazy(() => import("./pages/FeatureClub"))
const FeatureService = lazy(() => import("./pages/FeatureService"))
const BlogListings = lazy(() => import("./pages/BlogListings"))
const BlogDescription = lazy(() => import("./pages/BlogDescription"))
const About = lazy(() => import("./pages/About.js"))
const Contact = lazy(() => import("./pages/Contact.js"))
const Pricing = lazy(() => import('./pages/Pricing.js'));
const TermsConditions = lazy(() => import('./pages/TermsConditions.js'));
const PolicyPrivacy = lazy(() => import('./pages/PrivacyPolicy.js'));
const FestivalWrapper = lazy(() => import('./pages/Festival.js'));
const Testimonials = lazy(() => import('./pages/Testimonials.js'));

export default function App() {
  const location = useLocation();
  const showFooter = [
    "/",
    "/app",
    "/features",
    "/features/app",
    "/features/club",
    "/features/services",
    "/blogs",
    "/blogs-description",
    "/about",
    "/contact",
    "/pricing", ,
    "/testimonials"
  ].includes(location.pathname.endsWith("/") && location.pathname !== "/" ? location.pathname.slice(0, -1) : location.pathname);
  useEffect(function () {
    window.scrollTo(0, 0);
  }, [location])
  return (
    <section suppressHydrationWarning={true} className="w-full h-screen flex flex-col items-center justify-between">
      {(!location.pathname.startsWith("/festival-diwali") && !location.pathname.includes("/app"))
        && <OnLoadPopUp />}
      <Routes>
        <Route path="/" element={<RenderPage Component={Home} />} />
        <Route path="/app" element={<RenderPage Component={AppPage} />} />
        <Route path="/app/*" element={<RenderPage Component={AppPage} />} />
        <Route path="/features" element={<RenderPage Component={Features} />} />
        <Route path="/features/app" element={<RenderPage Component={FeatureApp} />} />
        <Route path="/features/club" element={<RenderPage Component={FeatureClub} />} />
        <Route path="/features/services" element={<RenderPage Component={FeatureService} />} />
        <Route path="/blogs" element={<RenderPage Component={BlogListings} />} />
        <Route path="/blogs/:id" element={<RenderPage Component={BlogDescription} />} />
        <Route path="/about" element={<RenderPage Component={About} />} />
        <Route path="/contact" element={<RenderPage Component={Contact} />} />
        <Route path="/pricing" element={<RenderPage Component={Pricing} />} />
        <Route path="/terms-and-conditions" element={<RenderPage Component={TermsConditions} />} />
        <Route path="/privacy-policy" element={<RenderPage Component={PolicyPrivacy} />} />
        <Route path="/festival-diwali/:id" element={<RenderPage Component={FestivalWrapper} />} />
        <Route path="/testimonials" element={<RenderPage Component={Testimonials} />} />
        <Route path="/admin/*" element={<RenderPage Component={Admin} />} />
        {/* <Route path="/auth" element={<Authentication />}>
          <Route path="" element={<Navigate to="login" replace />} />
          <Route path="login" index element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {showFooter && <Footer />}
    </section>
  )
}

function RenderPage({ Component }) {
  return <Suspense>
    <Component />
  </Suspense>
}