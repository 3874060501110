import { useEffect, useState } from "react"
import { FaApple, FaFacebookSquare, FaGooglePlay, FaYoutube } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import bannerImage from "../../images/onload-modal.png"
import { BsInstagram } from "react-icons/bs";

export default function OnLoadPopUp() {
    const [isOpened, setIsOpened] = useState(true);
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const playStoreLink = /android/i.test(userAgent) ? "https://play.google.com/store/apps/details?id=com.updevelop.wellness_z_mvvm" : "/app";
    const appStoreLink = /iPad|iPhone|iPod/i.test(userAgent) ? "https://apps.apple.com/us/app/wellnessz/id6478812964" : "/app";

    useEffect(function () {
        if (isOpened) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [isOpened])

    if (!isOpened) return <></>
    return <div className="h-screen w-screen fixed top-0 left-0 overflow-y-auto flex items-center justify-center z-[1000]">
        <div>
            <div className="md:max-w-[95%] xl:max-w-[1400px] bg-black bg-gradient-to-b from-[#50961340] to-black mx-auto mt-[500px] sm:mt-[300px] md:mt-[400px] lg:mt-40 xl:mt-0 p-[20px] md:p-[48px] text-white lg:flex items-center justify-center relative z-[1000] overflow-y-auto border-2 border-gray-700">
                <FaXmark className="text-[32px] absolute top-2 right-2 cursor-pointer" onClick={() => setIsOpened(false)} />
                <div>
                    <h1 className="max-w-[24ch] font-markazi font-bold text-[48px] md:text-[60px] mb-[32px] leading-[1]"><span className="text-[#67BC2A]">WellnessZ</span> is customer Generation App.</h1>
                    <p className="text-[18px] md:text-[30px">with First ever CRM for Wellness Coaches</p>

                    {/* FEATURES SECTION STARTS */}
                    <div className="my-[20px]">
                        <div className="flex items-center gap-4">
                            <IoIosCheckmarkCircle className="min-h-6 min-w-6 md:min-h-10 md:min-w-10 text-[#67BC2A]" />
                            <p className="text-[18px] font-semibold">Become a part of the leading Coach Community</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <IoIosCheckmarkCircle className="min-h-6 min-w-6 md:min-h-10 md:min-w-10 text-[#67BC2A]" />
                            <p className="text-[18px] font-semibold">Effective Client management and Retention</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <IoIosCheckmarkCircle className="min-h-6 min-w-6 md:min-h-10 md:min-w-10 text-[#67BC2A]" />
                            <p className="text-[18px] font-semibold">Upgrade with latest technology</p>
                        </div>
                    </div>
                    {/* FEATURES SECTION ENDS */}

                    {/* DOWNLOAD SECTION STARTS */}
                    <div className="flex flex-wrap items-center gap-4">
                        <p className="text-[20px] font-semibold">Available on</p>
                        <div className="flex flex-wrap items-center gap-4">
                            <Link target="_blank" to={playStoreLink} className="px-4 py-2 flex items-center gap-2 border-2 border-[#A6A6A6] rounded-[8px]">
                                <FaGooglePlay className="w-6 md:w-10 h-6 md:h-10 text-[#A6A6A6]" />
                                <div>
                                    <p className="text-[10px] md:text-[12px] uppercase leading-tight">Get it on </p>
                                    <p className="text-[16px] md:text-[20px] font-semibold leading-tight">Google Play</p>
                                </div>
                            </Link>
                            <Link target="_blank" to={appStoreLink} className="px-2 md:px-4 py-2 flex items-center gap-2 border-2 border-[#A6A6A6] rounded-[8px]">
                                <FaApple className="w-6 md:w-10 h-6 md:h-10 text-[#A6A6A6]" />
                                <div>
                                    <p className="text-[10px] md:text-[12px] leading-tight">Download on the</p>
                                    <p className="text-[16px] md:text-[20px] font-semibold leading-tight">App Store</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* DOWNLOAD SECTION ENDS */}

                    {/* FOLOW SECTION STARTS */}
                    <div className="mt-[40px] flex flex-wrap items-center gap-4">
                        <p className="text-[20px] font-bold">Follow Us On:</p>
                        <div className="flex flex-wrap items-center divide-x-2 gap-4">
                            <Link to="https://www.facebook.com/profile.php?id=61553253021745&mibextid=ZbWKwL/" target="_blank" className="flex items-center gap-1">
                                <FaFacebookSquare className="w-6 h-6 text-[#A6A6A6]" />
                                <p>Facebook</p>
                            </Link>
                            <Link to="https://instagram.com/wellnessz_official?igshid=MzMyNGUyNmU2YQ==" target="_blank" className="pl-4 flex items-center gap-1">
                                <BsInstagram className="w-6 h-6 text-[#A6A6A6]" />
                                <p>Instagram</p>
                            </Link>
                            <Link to="https://www.youtube.com/@WellnessZ-oe4xk" target="_blank" className="pl-4 flex items-center gap-1">
                                <FaYoutube className="w-6 h-6 text-[#A6A6A6]" />
                                <p>Youtube</p>
                            </Link>
                        </div>
                    </div>
                    {/* FOLOW SECTION ENDS */}
                </div>
                <img
                    src={bannerImage}
                    className="w-full lg:w-2/3 max-h-[400px] md:max-w-[500px] lg:block object-contain"
                />
            </div>
        </div>
    </div>
}