import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNLarXhTD55-LrdsCcAAjeMdU4UT58IK4",
  authDomain: "wellnesszplans.firebaseapp.com",
  projectId: "wellnesszplans",
  storageBucket: "wellnesszplans.firebasestorage.app",
  messagingSenderId: "109551135118",
  appId: "1:109551135118:web:f44cfcfc74e3387c33dcdf",
  measurementId: "G-82843G5BXF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const useFirebaseAnalytics = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const analytics = getAnalytics(app);
      logEvent(analytics, "page_view");
    }
  }, []);
};
