import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaRegEnvelope, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <section className="w-[100%] flex flex-col bg-black text-gray-300 px-8 xl:px-20 py-6 md:text-[0.8rem] ">
            <div className="flex gap-20 md:gap-6 lg:gap-0 flex-wrap justify-between py-12 ">
                <div className="flex flex-col gap-3 w-full md:w-[25%]">
                    <div className="w-fit bg-white bg-opacity-20 backdrop-blur-sm p-4 rounded-md border- 2 border-[]" to="/">
                        <img src="/logo.svg" alt="logo" className="w-10 md:w-16" />
                    </div>
                    <p className="max-w-[36ch] text-[18px]">© 2023 Mohi Lifestile Solutions Private Limited ® | All Rights Reserved<br /> Made with Love</p>
                    <div className="flex gap-4 cursor-pointer text-[1.1rem] text-[#67BC2A] ">
                        <Link to="https://www.facebook.com/profile.php?id=61553253021745&mibextid=ZbWKwL/">
                            <FaFacebookF />
                        </Link>
                        <Link to="https://instagram.com/wellnessz_official?igshid=MzMyNGUyNmU2YQ==">
                            <FaInstagram />
                        </Link>
                        <Link to="https://www.linkedin.com/company/wellnessz/">
                            <FaLinkedinIn />
                        </Link>
                        <Link to="https://www.youtube.com/@WellnessZ-oe4xk">
                            <FaYoutube />
                        </Link>
                    </div>
                </div>

                <div className="w-[30%] md:w-[auto] flex flex-col gap-2">
                    <h1 className="md:mb-4 opacity-100 font-bold text-white">Product</h1>
                    <Link to="/features" className="text-[18px]">Features</Link>
                    <Link to="/pricing" className="text-[18px]">Pricing</Link>
                </div>
                <div className="w-[30%] md:w-[auto] flex flex-col gap-2">
                    <h1 className="md:mb-4 opacity-100 font-bold text-white">Company</h1>
                    <Link to="/about" className="text-[18px]">About</Link>
                    <Link to="/contact" className="text-[18px]">Contact Us</Link>
                    <Link to="/blogs" className="text-[18px]">Blog</Link>
                </div>

                <div className="hidden md:flex flex-col gap-2">
                    <h1 className="md:mb-4 opacity-100 font-bold text-white">Support</h1>
                    <Link target="_blank" to="mailto:support@wellnessz.in" className="text-[18px]">Help center</Link>
                    <Link target="_blank" to="https://forms.gle/nv2VgTgGChLW2gBW6" className="text-[18px]">Feedback Form</Link>
                    <Link target="_blank" to="https://forms.gle/9d5p8VvQUGM9kKuWA" className="text-[18px]">Report a bug</Link>
                </div>
                <div className="text-[16px] flex flex-col gap-3">
                    <h1 className="md:mb-4 opacity-100 font-bold text-white">Contact us</h1>
                    <Link to="mailto:support@wellnessz.in" className=" flex items-center gap-3">
                        <FaRegEnvelope /> support@wellnessz.in
                    </Link>
                    <Link to="tel:+917888624347" className=" flex items-center gap-3">
                        <FaPhoneAlt /> +91 7888624347
                    </Link>
                    <Link to="https://www.google.com/maps/search/?api=1&query=B-689-690%2C%20New%20Amritsar%2C%20Amritsar%2C%20Punjab%2C%20143001" className=" flex items-center gap-3">
                        <FaLocationDot /> Amritsar, Punjab, India
                    </Link>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-2 items-center justify-between border-t-2 pt-6 mt-4 border-gray-500">
                <div className="flex flex-col md:flex-row gap-2 items-center justify-center">
                    <span className="px-2 opacity-70">All Rights Reserved</span>
                    <div className="text-[0.7rem] md:text-[0.8rem]">
                        <Link target="_blank" to="https://www.wellnessz.in/terms-and-conditions" className="text-[#67BC2A] px-2 border-x-2 cursor-pointer border-gray-500">
                            Terms and Conditions
                        </Link>
                        <Link target="_blank" to="https://www.wellnessz.in/privacy-policy" className="text-[#67BC2A] px-2 cursor-pointer ">
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}