import React from 'react';
import { Link } from 'react-router-dom';
import Banner from "./images/404_error.png"

export default function NotFound() {
    return (
        <div className="main mx-auto flex flex-col w-full items-center justify-center pb-4 pt-28">
            <img src={Banner} className="h-[50vh] md:h-[70vh]" alt="404 Error" />
            <h1 className="w-full text-center my-4 text-2xl font-semibold">
                We Couldn't find the Page you are Looking for
            </h1>
            <Link
                className="mx-auto rounded-md bg-[#509613] text-white px-2 py-1"
                to="/"
            >
                Go back
            </Link>
        </div>
    );
}
